import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./styled";

const Button = ({
  display,
  role,
  tag,
  onClick,
  href,
  target,
  type,
  disabled,
  children,
}) => {
  switch (tag) {
    default:
    case "button":
      return (
        <Styled.Button
          display={display}
          role={role}
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          {children}
        </Styled.Button>
      );
    case "link":
      return (
        <Styled.Link
          display={display}
          role={role}
          disabled={disabled}
          to={href}
        >
          {children}
        </Styled.Link>
      );
    case "a":
      return (
        <Styled.A
          display={display}
          role={role}
          disabled={disabled}
          href={href}
          target={target}
        >
          {children}
        </Styled.A>
      );
  }
};

export default Button;

Button.defaultProps = {
  display: "inline",
  role: "primary",
  type: "button",
  tag: "button",
  disabled: false,
};

Button.propTypes = {
  display: PropTypes.oneOf(["block", "inline"]),
  type: PropTypes.string,
  role: PropTypes.oneOf(["primary", "secondary", "flat"]),
  tag: PropTypes.oneOf(["button", "link", "a"]),
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.oneOf(["", "_blank"]),
  disabled: PropTypes.bool,
};
