import { Container, setConfiguration } from "react-grid-system";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { Global } from "./components";
import { Registration, Error, Success } from "./containers";
import { BounceSwitch } from "./components";

function App() {
  setConfiguration({ maxScreenClass: "sm" });

  return (
    <Container>
      <Global />
      <BrowserRouter>
        <BounceSwitch>
          <Route path={"/"} component={Registration} exact />
          <Route path={"/success"} component={Success} exact />
          <Route path={"/error"} component={Error} exact />
          <Redirect to={"/error"} />
        </BounceSwitch>
      </BrowserRouter>
    </Container>
  );
}

export default App;
