import * as GStyled from "../../components/styled";
import logo from "../../static/logo2.png";
import {
  MobilePageHeader,
  MobilePageDescription,
  Button,
} from "../../components";
import { Row } from "react-grid-system";
import CenterContainer from "./styled";
const Success = () => {
  return (
    <CenterContainer>
      <div>
        <GStyled.RowImage image={logo} size={"100px"} />
        <Row>
          <GStyled.Col>
            <MobilePageHeader>Поздравляем!</MobilePageHeader>
          </GStyled.Col>
        </Row>

        <Row>
          <GStyled.Col>
            <MobilePageDescription>
              Система настроена и готова к работе, Осталось выполнить пару
              простых действий.
            </MobilePageDescription>
          </GStyled.Col>
        </Row>

        <Row>
          <GStyled.Col>
            <Button
              display={"block"}
              type={"primary"}
              tag={"a"}
              href={"https://6tamp.ru/help"}
              target={"_blank"}
            >
              Как пользоваться?
            </Button>
          </GStyled.Col>
        </Row>
      </div>
    </CenterContainer>
  );
};

export default Success;
