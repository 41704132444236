import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiService } from "../utils";

const useRegistration = () => {
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [error, setError] = useState("");
  const send = useCallback(
    async ({ id, name, surname, email, password, phone, coffeeHouse }) => {
      try {
        const { data } = await apiService.post(`/user/invite/${id}`, {
          name,
          lastName: surname,
          email: email.toLowerCase(),
          password,
          phone,
          chainTitle: coffeeHouse,
        });
        setResult(data.result);
      } catch (e) {
        setResult(false);
        setError("Ошибка при отправке данных, попробуйте еще раз");
      }
    },
    []
  );

  useEffect(() => {
    if (result) {
      history.push("/success");
    }
  }, [result, history]);

  return { send, result, error };
};

export default useRegistration;
