import CenterContainer from "../Success/styled";
import * as GStyled from "../../components/styled";
import logo from "../../static/logo2.png";
import { Row } from "react-grid-system";
import {
  Button,
  MobilePageDescription,
  MobilePageHeader,
} from "../../components";

const Error = () => {
  return (
    <CenterContainer>
      <div>
        <GStyled.RowImage image={logo} size={"100px"} />
        <Row>
          <GStyled.Col>
            <MobilePageHeader>Страница не найдена</MobilePageHeader>
          </GStyled.Col>
        </Row>
        <Row>
          <GStyled.Col>
            <MobilePageDescription>
              Вы уверены, что оказались там, где хотели?
            </MobilePageDescription>
          </GStyled.Col>
        </Row>
        <Row>
          <GStyled.Col>
            <Button
              display={"block"}
              type={"flat"}
              tag={"a"}
              href={"https://6tamp.ru"}
            >
              Узнать про 6tamp
            </Button>
          </GStyled.Col>
        </Row>
      </div>
    </CenterContainer>
  );
};

export default Error;
