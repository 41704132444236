import React, { useEffect } from "react";
import { Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useHistory } from "react-router-dom";
import {
  MobilePageHeader,
  MobilePageDescription,
  TextInput,
  Button,
} from "../../components";
import * as Styled from "../../components/Registration/styled";
import * as GStyled from "../../components/styled";
import logo from "../../static/logo.png";
import { useRegistration } from "../../hooks";
import { apiService, isError } from "../../utils";

const fields = [
  {
    label: "name",
    placeholder: "Имя",
  },
  {
    label: "surname",
    placeholder: "Фамилия",
  },
  {
    label: "email",
    placeholder: "E-mail",
  },
  {
    label: "password",
    placeholder: "Пароль",
    type: "password",
  },
  {
    label: "phone",
    placeholder: "Телефон",
    mask: "+7 (999) 999-99-99",
  },
  {
    label: "coffeeHouse",
    placeholder: "Компания",
  },
];

const errors = {
  isRequired: "Это поле обязательно",
  invalidName: "Введите настоящее имя",
  invalidPassword:
    "В пароле допускаются только латинские буквы и цифры (обязательно 1 буква и 1 цифра)",
  invalidValue: "Неверный ввод",
  long: "Слишком длинное значение",
  short: "Слишком короткое значение",
};

const Registration = () => {
  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const id = params.get("id");

  const { send, result, error } = useRegistration();

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      password: "",
      phone: "",
      coffeeHouse: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .min(2, errors.invalidName)
        .max(16, errors.invalidName),
      surname: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .min(2, errors.invalidName)
        .max(16, errors.invalidName),
      email: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .email(errors.invalidValue)
        .max(32, errors.long),
      password: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .min(8, errors.short)
        .max(32, errors.long)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          errors.invalidPassword
        ),
      phone: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .length(18, errors.invalidValue),
      coffeeHouse: Yup.string()
        .nullable()
        .required(errors.isRequired)
        .min(2, errors.short)
        .max(32, errors.long),
    }),
    onSubmit: async (values) => {
      await send({ id, ...values });
    },
  });

  useEffect(() => {
    if (!id) history.push("error");
    const fetch = async (id) => {
      try {
        const { data } = await apiService.get(`/user/invite/${id}`);
        if (data.activated) {
          const api = process.env.REACT_APP_TYPE === "PROD" ? "6tamp" : "lip1";
          window.location.href = `https://reg.${api}.ru/?id=${data.location}`;
        }
      } catch (e) {
        history.push("/error");
      }
    };
    fetch(id);
  }, [history, id]);

  return (
    <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <GStyled.RowImage image={logo} size={"100px"} />

      <Row>
        <GStyled.Col>
          <MobilePageHeader>Активация наклейки</MobilePageHeader>
        </GStyled.Col>
      </Row>

      <Row>
        <GStyled.Col>
          <MobilePageDescription>
            Заполни поля ниже, чтобы активировать наклейку твоего заведения
          </MobilePageDescription>
        </GStyled.Col>
      </Row>

      {fields.map(({ label, placeholder, type, mask }, index) => (
        <Row>
          <GStyled.Col>
            <TextInput
              key={`${label}_${index}`}
              id={label}
              name={label}
              placeholder={placeholder}
              mask={mask}
              onFocus={
                label === "phone"
                  ? () => formik.setFieldValue("+7 (", true)
                  : null
              }
              type={type}
              error={isError(formik, label) ? formik.errors[label] : null}
            />
          </GStyled.Col>
        </Row>
      ))}

      <Row>
        {!result && error ? (
          <GStyled.Col>
            <Styled.ErrorMessage>{error}</Styled.ErrorMessage>
          </GStyled.Col>
        ) : null}
      </Row>

      <Row>
        <GStyled.Col>
          <Button
            display={"block"}
            disabled={!formik.dirty}
            tag={"button"}
            type={"submit"}
          >
            Сохранить
          </Button>
        </GStyled.Col>
        <GStyled.Col>
          <Button
            display={"block"}
            role={"flat"}
            type={"button"}
            tag={"a"}
            href={"https://6tamp.ru/help"}
            target={"_blank"}
          >
            Как пользоваться?
          </Button>
        </GStyled.Col>
      </Row>
    </form>
  );
};

export default Registration;
