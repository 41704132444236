import styled from "styled-components";
import { Col as GridCol } from "react-grid-system";

export const Col = styled(GridCol).attrs({
  xs: 12,
})``;

export const RowImage = styled.div`
  background-image: url(${(state) => state.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: ${(state) => state.size};
  width: 100%;
  margin: 10px 0;
`;
